import { css } from "@emotion/react";
import Image from "next/image";

import {
  colors,
  breakpoints,
} from "@mytutor/shared-react-web-components/lib/theme";

const featuredBarStyle = css`
  font: inherit;
  background-color: ${colors.neutral1};
  min-height: 110px;
  display: flex;
  align-items: center;

  .featured-small {
    display: none;
  }

  div {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: ${breakpoints.largeDesktop}px) {
      width: 100%;
    }
  }

  p {
    min-width: 130px;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: 0;
    padding: 0 10px 0 5px;
    margin: 0;
    color: ${colors.neutral8};
  }

  @media (max-width: ${breakpoints.tablet}px) {
    .featured-big {
      display: none;
    }

    .featured-small {
      display: inherit;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    p {
      padding: 0;
      margin-top: 16px;
    }

    span {
      margin: 16px;
      width: 160px;
      height: 48px;
    }
  }

  @media (max-width: ${breakpoints.phone}px) {
    span {
      width: 128px;
      height: 38px;
    }
  }

  @media (max-width: ${breakpoints.smallPhone}px) {
    span {
      margin: 8px 0;
      width: 96px;
      height: 28px;
    }
  }
`;

const FeaturedBar = () => (
  <section css={featuredBarStyle}>
    <div className="featured-big">
      <p>As featured in</p>
      <Image
        src={"https://cdn.mytutor.co.uk/images/sundaytimes_48.svg"}
        alt={"Sunday Times"}
        width={175}
        height={48}
      />
      <Image
        src={"https://cdn.mytutor.co.uk/images/guardian_48.svg"}
        alt={"The Guardian"}
        width={175}
        height={48}
      />
      <Image
        src={"https://cdn.mytutor.co.uk/images/uploads/bbc_image_resized.gif"}
        alt={"BBC"}
        width={138}
        height={48}
      />
      <Image
        src={"https://cdn.mytutor.co.uk/images/sky_48.svg"}
        alt={"Sky"}
        width={100}
        height={48}
      />
    </div>

    <div className="featured-small">
      <p>As featured in</p>
      <div>
        <span>
          <Image
            src={"https://cdn.mytutor.co.uk/images/sundaytimes_48.svg"}
            alt={"Sunday Times"}
            width={160}
            height={48}
          />
        </span>
        <span>
          <Image
            src={"https://cdn.mytutor.co.uk/images/guardian_48.svg"}
            alt={"The Guardian"}
            width={160}
            height={48}
          />
        </span>
      </div>
      <div>
        <span>
          <Image
            src={
              "https://cdn.mytutor.co.uk/images/uploads/bbc_image_resized.gif"
            }
            alt={"BBC"}
            width={160}
            height={48}
          />
        </span>
        <span>
          <Image
            src={"https://cdn.mytutor.co.uk/images/sky_48.svg"}
            alt={"Sky"}
            width={160}
            height={48}
          />
        </span>
      </div>
    </div>
  </section>
);

export default FeaturedBar;
